import React from 'react';
import { createRoot } from 'react-dom/client';

import ChatBox from './components/WebChat/index.js'
import * as serviceWorker from './serviceWorker.js';

const container = document.getElementById('root-webchat');
const root = createRoot(container);

root.render(
  //<React.StrictMode>
    <ChatBox />
  //</React.StrictMode>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()


/*import React from 'react';
import ReactDOM from 'react-dom';

import ChatBox from './components/WebChat'
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <ChatBox />
  </React.StrictMode>,
  document.getElementById('root-webchat')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()*/

