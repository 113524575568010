import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, ConversationHeader, Avatar, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid';

import callAPI from './api.js';
import Socket from './socket.js'

import upload from './uploadFile.js'

import MarkdownToJSX from 'markdown-to-jsx';

const logoEmpresa = require("../react-chat/assets/logoEmpresa.png")
const chatCloseIcon = require("../react-chat/assets/icons-x.png")
const chatOpenIcon = require("../react-chat/assets/icons8-chat-50.png")
const userAvatar = require("../react-chat/assets/icons-usuario.png")
const avatarChatBot = require("../react-chat/assets/avatarChatBot.png")
const fileIcon = require("../react-chat/assets/icons-archivo.png")

const MyComponent = () => {
    const inputFileRef = useRef(null);
    const [uploadFileFlag, setUploadFileFlag] = useState(false);
    const [messages, setMessages] = useState([]);
    const [menuItems, setMenuItems] = useState({});
    const [showBot, toggleBot] = useState(false);

    const addNewMessage = async (newMessage, sender, type) => {
        setMessages((prevMessages) => {
            const newId = prevMessages.length + 1;
            const newMessageObject = { id: newId, content: newMessage, sender: sender, type: type };
            return [...prevMessages, newMessageObject];
        });
    };
    useEffect(() => {
       
        if (Cookies.get("conversationId") === undefined) {
            Cookies.set("conversationId", uuidv4(), { expires: 5 })
        }
        handleNewMessage('menu', 'true');
    }, []);
    const valueButton = async (opciones) => {
        let newOptions = []
        opciones.forEach(element => {
            element.value = { value: (element.value).toString(), label: element.label }
            newOptions.push(element)
        });
        return newOptions
    };
    const handleSubmit = async (mensaje, url) => {
        try {
            let conversationId = Cookies.get("conversationId")
            if (Cookies.get("conversationId") === undefined) {

                Cookies.set("conversationId", uuidv4(), { expires: 5 })
                conversationId = Cookies.get("conversationId")
            }
            let result = await callAPI(mensaje, conversationId, url, "30");//id Promo
            return result


        } catch (error) {
            await addNewMessage("Se ha producido un error", 'user', 'text')
        }
    };
    const handleAttachClick = () => {
        inputFileRef.current.click();
    };
    const handleFileSelect = async (event) => {
        if ((event.target.files).length > 0) {
            setUploadFileFlag((prev) => !prev)
            const selectedFile = event.target.files[0];
            const datos = await upload.procesarCarga(selectedFile, "jaquematte-recursos-privados", (progressBar) => { })

            let extencion = "";
            let payload = {
                archivo: JSON.stringify(datos),
                fecha: getFechaDocChat(),
                nombre: selectedFile.name,
                extencion: extencion,
                bucket: "jaquematte-recursos-publicos",
                path: "hablar-agente/adjuntos-clientes/",
            };
            let res = await upload.moverArchivo(payload);
            await addNewMessage(JSON.stringify(res.data), "client", 'custom')
            setUploadFileFlag((prev) => !prev)
            await handleSubmit(res.data.nombre, res.data.url)
            inputFileRef.current.value = '';
        }
    };
    const handleNewMessage = async (newMessage, setmenu) => {
        setMenuItems({});
        if (setmenu !== 'true') {
            await addNewMessage(newMessage, 'client', 'text')
        }
        let result = await handleSubmit(newMessage, '')
        let data = result.data.data.data
        if (data[0].tipoMensaje !== "N/A") {
            data.forEach(async element => {
                if (element.tipoMensaje === "text") {
                    await addNewMessage(element.texto, 'user', 'text')
                } else if (element.tipoMensaje === "markdown") {
                    await addNewMessage(element.texto, 'user', 'markdown')
                } else {
                    if (element.mensaje !== "") {
                        await addNewMessage(element.mensaje, 'user', 'text')
                    }
                    if (element.indicacion !== "") {
                        await addNewMessage(element.indicacion, 'user', 'text')
                    }
                    setMenuItems({ options: await valueButton(element.opciones) })
                }
            });
        }
    };
    const handleButtonClick = async (newMessage) => {
        setMenuItems({});
        await addNewMessage(newMessage.label, 'client', 'text')
        let result = await handleSubmit(newMessage.value, '')
        let data = result.data.data.data
        if (data[0].tipoMensaje !== "N/A") {
            data.forEach(async element => {
                if (element.tipoMensaje === "text") {
                    await addNewMessage(element.texto, 'user', 'text')
                } else {
                    if (element.mensaje !== "") {
                        await addNewMessage(element.mensaje, 'user', 'text')
                    }
                    if (element.indicacion !== "") {
                        await addNewMessage(element.indicacion, 'user', 'text')
                    }
                    setMenuItems({ options: await valueButton(element.opciones) })
                }
            });
        }
    };
    const agentMessage = async (newMessage) => {
        if (newMessage.type === "text") {
            if (newMessage.url && newMessage.url !== "") {
                await addNewMessage(JSON.stringify({ nombre: newMessage.message, url: newMessage.url }), "user", 'custom')
            } else {
                await addNewMessage(newMessage.message, 'user', 'text')
            }
        }
    };
    const getFechaDocChat = () => {
        let f = new Date();
        return (
            f.getFullYear() + "-" +
            (f.getMonth() + 1) + "-" +
            f.getDate() + "-" +
            f.getHours() + "-" +
            f.getMinutes() + "-" +
            f.getSeconds()
        );
    };
    //para levantar en otra ventana enlaces
    const LinkRenderer = ({ href, children }) => (
        <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    );
    const options = {
        overrides: {
            a: LinkRenderer,
        },
    };

    return (
        <div className="App" style={{ position: "relative" }}>
            {showBot && (
                <>
                    <Socket id={Cookies.get("conversationId")} viewMessage={agentMessage} />
                    <MainContainer className=".sc-chat-window">
                        <ChatContainer>
                            <ConversationHeader>
                                <ConversationHeader.Content>
                                    <div className="div-header">
                                        <div className="column" id="div-title" >Habla con nosotros</div>
                                        <div id="div-button" className="column">
                                            <button id="div-button" onClick={() => toggleBot((prev) => !prev)}>
                                                <img className="icon-2" src={chatCloseIcon} alt="Icono"></img>
                                            </button>
                                        </div>
                                    </div>
                                </ConversationHeader.Content>
                                <Avatar src={logoEmpresa} />
                            </ConversationHeader>
                            <MessageList typingIndicator={uploadFileFlag ? <TypingIndicator content="Uploading File" /> : null}>
                                {messages.map((message) => {
                                    if (message.type === 'text') {
                                        return (
                                            <Message
                                                avatarPosition={message.sender === 'user' ? "tl" : "tr"}
                                                key={message.id}
                                                model={{
                                                    message: message.content,
                                                    direction: message.sender === 'user' ? 'incoming' : 'outgoing',
                                                }}>
                                                <Avatar src={message.sender === 'user' ? avatarChatBot : userAvatar} />
                                            </Message>
                                        );
                                    } else if (message.type === 'custom') {
                                        return (
                                            <Message
                                                avatarPosition={message.sender === 'user' ? "tl" : "tr"}
                                                key={message.id}
                                                model={{ direction: message.sender === 'user' ? 'incoming' : 'outgoing' }}>
                                                <Message.CustomContent>
                                                    <a href={(JSON.parse(message.content)).url} className="file-link" target="_blank" rel="noopener noreferrer">
                                                        <button className="btn-borde">
                                                            <img className="icon-2" src={fileIcon} alt="Icono"></img>
                                                            <div>{(JSON.parse(message.content)).nombre}</div>
                                                        </button>
                                                    </a>
                                                </Message.CustomContent>
                                                <Avatar src={message.sender === 'user' ? avatarChatBot : userAvatar} />
                                            </Message>);
                                    } else if (message.type === 'markdown') {
                                        return (
                                            <Message
                                                avatarPosition={message.sender === 'user' ? "tl" : "tr"}
                                                key={message.id}
                                                model={{ direction: message.sender === 'user' ? 'incoming' : 'outgoing' }}>
                                                <Message.CustomContent>
                                                    <MarkdownToJSX options={options}>{message.content}</MarkdownToJSX>
                                                </Message.CustomContent>
                                                <Avatar src={message.sender === 'user' ? avatarChatBot : userAvatar} />
                                            </Message>
                                        );
                                    }
                                })}
                                {menuItems.options ? (
                                    <Message avatarPosition="tl" model={{ direction: "incoming" }} >
                                        <Message.CustomContent>
                                            <div>
                                                <div className='btn-menu'>
                                                    {(menuItems.options).map((menuItem, index) => (
                                                        <div key={index}>
                                                            <button className="btn-borde" onClick={() => handleButtonClick(menuItem.value)}>
                                                                {menuItem.label}
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Message.CustomContent>
                                        <Avatar src={avatarChatBot} />
                                    </Message>
                                ) : null}
                            </MessageList>
                            <MessageInput placeholder="Escribe tu mensaje" onAttachClick={handleAttachClick} onSend={handleNewMessage} />
                        </ChatContainer>
                    </MainContainer>
                </>
            )}
            <input type="file" style={{ display: 'none' }} ref={inputFileRef} onChange={handleFileSelect}></input>
            <button
                className="app-chatbot-button"
                onClick={() => toggleBot((prev) => !prev)}
            >
                {showBot ? (
                    <div>
                        <img className="icono" src={chatCloseIcon} alt="Icono"></img>
                    </div>
                ) : (
                    <div>
                        <img className="icono" src={chatOpenIcon} alt="Icono"></img>
                    </div>
                )}
            </button>
        </div>
    );
};

export default MyComponent;